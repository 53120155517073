export const getSeoData = data => {
  if (!data) {
    return {}
  }

  const { title, description, share_image } =
    data && data.length > 0 && data[0] ? data[0] : data

  return {
    title: title ? title : null,
    description: description ? description : null,
    ogImage: share_image ? share_image : null,
  }

  return {}
}
