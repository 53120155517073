import React from "react"
import Helmet from "react-helmet"

const SeoWrapper = ({ description, title, ogImage, type, url }) => {
  if (ogImage) {
    if (ogImage.startsWith("//")) {
      ogImage = "https:" + ogImage
    }
  }

  return (
    <Helmet defer={false}>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}
      {title && <meta property="og:image:alt" content={title} />}
      {title && <meta property="twitter:image:alt" content={title} />}

      {description && <meta name="description" content={description} />}
      {description && <meta name="og:description" content={description} />}

      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogImage && <meta property="twitter:image:src" content={ogImage} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {type && <meta property="og:url" content={url} />}
      {type && <meta property="twitter:url" content={url} />}
      <html lang="sv" />
    </Helmet>
  )
}

export default SeoWrapper
