import React from "react"
import styled, { ThemeProvider } from "styled-components"
import theme from "src/helpers/theme"
import { Location } from "@reach/router"

import "src/assets/styles/reset.scss"
import "src/assets/styles/body.scss"
import fonts from "src/assets/fonts/fonts"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const AppStyles = styled.div`
  ${fonts}
  font-family: "Tlwg-Typewriter", "Courier New", Courier, monospace;
  font-weight: 300;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  img,
  video {
    width: 100%;
    height: auto;
  }

  a {
    color: white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .o-image {
    &:not(.critical) {
      opacity: 0;
      transition: opacity 400ms ease-out;
    }
  }

  button {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    line-height: inherit;
    padding: 0;
    text-transform: inherit;
    letter-spacing: inherit;
    text-align: center;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    cursor: pointer;
  }
`

export default ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <Location>
        {({ location }) => {
          return <AppStyles>{element}</AppStyles>
        }}
      </Location>
    </ThemeProvider>
  )
}
