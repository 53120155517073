import { css } from "styled-components"

import TlwgTypewriterWoff from "./TlwgTypewriter/TlwgTypewriter.woff"
import TlwgTypewriterWoff2 from "./TlwgTypewriter/TlwgTypewriter.woff2"

import TlwgTypewriterBoldWoff from "./TlwgTypewriter/TlwgTypewriter-Bold.woff"
import TlwgTypewriterBoldWoff2 from "./TlwgTypewriter/TlwgTypewriter-Bold.woff2"

import TlwgTypewriterObliqueWoff from "./TlwgTypewriter/TlwgTypewriter-Oblique.woff"
import TlwgTypewriterObliqueWoff2 from "./TlwgTypewriter/TlwgTypewriter-Oblique.woff2"

import TlwgTypewriterBoldObliqueWoff from "./TlwgTypewriter/TlwgTypewriter-BoldOblique.woff"
import TlwgTypewriterBoldObliqueWoff2 from "./TlwgTypewriter/TlwgTypewriter-BoldOblique.woff2"

export default css`
  @font-face {
    font-family: "Tlwg-Typewriter";
    src: url(${TlwgTypewriterWoff}) format("woff"),
      url(${TlwgTypewriterWoff2}) format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Tlwg-Typewriter";
    src: url(${TlwgTypewriterBoldWoff}) format("woff"),
      url(${TlwgTypewriterBoldWoff2}) format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: "Tlwg-Typewriter";
    src: url(${TlwgTypewriterObliqueWoff}) format("woff"),
      url(${TlwgTypewriterObliqueWoff2}) format("woff2");
    font-weight: 300;
    font-style: italic;
    font-display: block;
  }

  @font-face {
    font-family: "Tlwg-Typewriter";
    src: url(${TlwgTypewriterBoldObliqueWoff}) format("woff"),
      url(${TlwgTypewriterBoldObliqueWoff2}) format("woff2");
    font-weight: 500;
    font-style: italic;
    font-display: block;
  }
`
