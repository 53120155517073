import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import styled from "styled-components"
import SeoWrapper from "src/components/SeoWrapper/SeoWrapper"
import { getSeoData } from "src/helpers/getSeoData"

const Layout = styled.div``

export default ({ children, location }) => {
  // Get static site metadata
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: { title: metaTitle, description: metaDescription },
    },
  } = data ? data : null

  // Get dynamic site metadata
  const seo = Object.assign(
    {},
    children.props &&
      children.props.pageContext &&
      children.props.pageContext.global_seo &&
      children.props.pageContext.global_seo.content
      ? children.props.pageContext.global_seo.content
      : { title: metaTitle, description: metaDescription }
  )

  const seoData = seo ? getSeoData([seo]) : null
  return (
    <Layout>
      {data && (
        <Helmet
          defaultTitle={seoData.title}
          titleTemplate={`${seoData.title}`}
          type="website"
        >
          <html lang="sv" />
          <script src="/script.js" />
        </Helmet>
      )}
      {seoData && <SeoWrapper {...seoData} />}

      {children}
    </Layout>
  )
}
