const theme = {
  breakpoints: [
    "300px",
    "374px",
    "765px",
    "1023px",
    "1025px",
    "1439px",
    "1599px",
  ],
  colors: {
    black: "#000000",
    white: "#FFFFFF",
    yellow: "#FFC217",
  },
  space: {
    0: "0px",
    1: "8px",
    2: "16px",
    3: "24px",
    4: "32px",
    5: "40px",
    6: "48px",
    7: "56px",
    8: "64px",
    9: "72px",
    10: "80px",
    11: "88px",
    12: "96px",
    13: "104px",
    14: "112px",
    15: "120px",
    16: "128px",
    17: "136px",
    18: "144px",
    19: "152px",
    20: "160px",
    21: "220px",
  },
  fontSizes: {
    0: "16px",
  },
  mixins: {
    imageObjectFit: (position = "center") => `
      object-fit: cover;
      object-position: ${position};
      `,
    positionCover: () => `
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `,
    overlay: (color = "rgba(0,0,0,0.2)") => `
      content: "";
      background-color: ${color};
      `,
    textElipsis: () => `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      `,
  },
}

export default theme
